<template>
  <section class="favorites-selector">
    <div class="title titleContainer">
      <div>
        {{ 'Events' }}
      </div>
      <div @click="forDropdown" class="titleChevron">
        <ChevronIcon :width="24" :height="24" :rotation="rotation" />
      </div>
    </div>
    <section
      :class="{
        'main-favorite-container-none': !dropdown,
        'main-favorite-container': dropdown,
      }"
    >
      <div class="main-favorite-selector">
        <div
          @click="routerChange('all')"
          :class="{
            'selected-list p-1': returnUrl === 'all',
            'main-list': returnUrl !== 'all',
          }"
        >
          <AllfavIcon
            :boolean="true"
            :color="
              returnUrl === 'all'
                ? 'white'
                : 'var(--settings-registration-current-event-button-background)'
            "
          />
          <div
            :class="{
              'selected-list-text p-1': returnUrl === 'all',
              'main-list-text': returnUrl !== 'all',
            }"
          >
            {{ 'All' }}
          </div>
        </div>
        <div
          @click="routerChange('live')"
          :class="{
            'selected-list p-1': returnUrl === 'live',
            'main-list': returnUrl !== 'live',
          }"
        >
          <LiveIcon
            :color="
              returnUrl === 'live'
                ? 'white'
                : 'var(--settings-registration-current-event-button-background)'
            "
          />
          <div
            :class="{
              'selected-list-text p-1': returnUrl === 'live',
              'main-list-text': returnUrl !== 'live',
            }"
          >
            {{ 'Live' }}
          </div>
        </div>
        <div
          @click="routerChange('upcoming')"
          :class="{
            'selected-list p-1': returnUrl === 'upcoming',
            'main-list': returnUrl !== 'upcoming',
          }"
        >
          <UpcomingIcon
            :color="
              returnUrl === 'upcoming'
                ? 'white'
                : 'var(--settings-registration-current-event-button-background)'
            "
          />
          <div
            :class="{
              'selected-list-text p-1': returnUrl === 'upcoming',
              'main-list-text': returnUrl !== 'upcoming',
            }"
          >
            {{ 'Upcoming' }}
          </div>
        </div>
        <div
          @click="routerChange('vod')"
          :class="{
            'selected-list p-1': returnUrl === 'vod',
            'main-list': returnUrl !== 'vod',
          }"
        >
          <OnDemandIcon
            :color="
              returnUrl === 'vod'
                ? 'white'
                : 'var(--settings-registration-current-event-button-background)'
            "
          />
          <div
            :class="{
              'selected-list-text p-1': returnUrl === 'vod',
              'main-list-text': returnUrl !== 'vod',
            }"
          >
            {{ 'OnDemand' }}
          </div>
        </div>
      </div>
      <div v-if="isLogged" class="list-favorite-selector">
        <div class="subtitle">
          <div class="my-auto">{{ 'Favorites' }} </div>  <div :class="{
              'selected-list-all ml-auto text-white text-center mb-0': getSelectedFavorite === 'all',
              'ml-auto unslected-all': !getSelectedFavorite !== 'all',
            }" @click="selectAllFavorites">{{ 'All Favorites' }} </div>
        </div>
        <div v-for="(favorite, index) in favorites" :key="favorite.id">
          <SideBarUpcomingClickableFavorite
            :favorite="favorite"
            :position="index"
          />
        </div>
      </div>
      <div v-else>
        <div class="subtitle">
          {{ 'Favorites' }}
        </div>
        <div class="subText">
          {{ 'Please log in to start favoriting' }}
        </div>
      </div>
    </section>
  </section>
</template>

<script>
// import ls from "@/utils/locale-storage";
import { EventBus } from '@/utils/event-bus';
import LiveIcon from '@/components/icons/Live';
import StarIcon from '@/components/icons/Star';
import AllfavIcon from '@/components/icons/Allfav';
import UpcomingIcon from '@/components/icons/Upcoming';
import OnDemandIcon from '@/components/icons/OnDemand';
import ChevronIcon from '@/components/icons/Chevron';
import SideBarUpcomingClickableFavorite from '@/components/base-components/Favorites/SideBarUpcomingClickableFavorite';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'SideBarUpcomingSelector',
  components: {
    AllfavIcon,
    LiveIcon,
    UpcomingIcon,
    OnDemandIcon,
    SideBarUpcomingClickableFavorite,
    ChevronIcon,
    StarIcon,
  },
  data() {
    return {
      favorites: [],
      rememberList: [],
      dropdown: false,
    };
  },
  computed: {
    ...mapGetters({
      getFavorites: 'getFavorites',
      getSelectedFavorite: 'getSelectedFavorite',
    }),
    ...mapState({
      dictionary: (state) => state.event.flatEventsCategoriesDictionary,
    }),
    isLogged() {
      return this.$store.getters.isLogged;
    },
    rotation() {
      return this.dropdown ? 'bottom' : 'right';
    },
    returnUrl() {
      return this.$route.name;
    },
  },
  watch: {
    getFavorites(newValue, oldValue) {
        this.initialize();      
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
      // call the get function for user's get
      let getUserFavorites = this.getFavorites;
      let data = [];

      getUserFavorites.forEach((favorite) => {
        let index;
        if (favorite.favorite_type === 'category') {
          index = this.dictionary.findIndex((dict) => {
            return dict.id === favorite.favorite_id;
          });
        }
        if (index !== -1 || favorite.favorite_type !== 'category') {
          data.push({
            ...favorite,
            name: this.dictionary[index]?.name
              ? this.dictionary[index].name
              : favorite.name,
            image: this.dictionary[index]?.image
              ? this.dictionary[index].image
              : '',
          });
        }
      });
      this.favorites = data;
    },
    forDropdown() {
      this.dropdown = !this.dropdown;
    },
    selectAllFavorites() {
      if(this.getSelectedFavorite !== 'all'){
      this.$store.commit('UPDATE_SELECT_FAVORITE', 'all');
      EventBus.$emit('refreshFavoritesSpecific');
      } else {
      this.$store.commit('UPDATE_SELECT_FAVORITE', '');
      EventBus.$emit('refreshFavoritesSpecific');
      }
    },
    routerChange(name) {
      if (this.$route.name === name) return;
      // EventBus.$emit('refreshFavoritesSpecific', this.getSelectedFavorite);
      if (name === 'all') {
        //EventBus.$emit('resetPickedFavorite');
        EventBus.$emit('resetSearchFavorite');
        //this.$store.commit('UPDATE_SELECT_FAVORITE', '');
        this.$router.push({
          path: `/favorites`,
        });
      } else {
        this.$router.push({
          path: `/favorites/${name}`,
        });
      }
      this.dropdown = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/css/colors';
@import 'src/assets/css/mixins';

@include media-min(sm) {
  .title {
    text-align: start;
    text-transform: none;
    font-style: normal;
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  .titleContainer {
    display: block;
  }
  .titleChevron {
    display: none;
  }
  .favorites-selector {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}

@include media-max(sm) {
  .title {
    text-align: start;
    text-transform: none;
    font-style: normal;
    font-size: 1.25rem;
  }
  .titleContainer {
    background: white;
    padding: 0.75rem 2rem;
    margin-bottom: 1rem;
    display: flex;
  }
  .titleChevron {
    display: block;
    margin-left: auto;
  }
  .main-favorite-container {
    margin: 0;
    padding: 1rem 2rem;
    background: white;
    position: absolute;
    top: 8rem;
    left: 0;
    right: 0;
    z-index: 100;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
  }
  .main-favorite-container-none {
    display: none;
  }
}

.unslected-all{
  cursor: pointer;
  border-radius: 5px;
  padding: 6px 6px;
  border-width: 2px;
  border-style: solid;
  border-color: var(--settings-watch-button-background);
}
.subtitle {
  display: flex;
  text-align: start;
  text-transform: none;
  font-style: normal;
  font-size: 1rem;
  margin-bottom: 1rem;
}
.subText {
  text-align: start;
  text-transform: none;
  font-style: normal;
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 1rem;
}
.main-favorite-selector {
  margin-bottom: 1rem;
  position: relative;
}

.main-list {
  display: grid;
  grid-template-columns: 2rem 1fr;
  margin-bottom: 1rem;
  cursor: pointer;
}
.main-list-text {
  text-align: start;
  font-style: normal;
  text-decoration: none;
  color: $text-primary;
  text-transform: none;
  margin-left: 2rem;
}
.list-favorite-selector {
  overflow-y: auto;
}
.selected-list {
  border-radius: 5px;
  padding: 6px 3px;
  background-color: var(--settings-watch-button-background);
  display: grid;
  grid-template-columns: 2rem 1fr;
  margin-bottom: 1rem;
  cursor: pointer;
}

.selected-list-all {
  border-radius: 5px;
  padding: 6px 6px;
  background-color: var(--settings-watch-button-background);
  display: grid;
  margin-bottom: 1rem;
  cursor: pointer;
}
.selected-list-text {
  color: white;
  text-align: start;
  font-style: normal;
  text-decoration: none;
  text-transform: none;
  margin-left: 1.4rem;
  margin-right: 1rem;
}
</style>
