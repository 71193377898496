<template>
  <section class="grid-wrapper">
    <SideBarUpcoming v-if="dictionary.length > 0" />
    <div class="max-container">
      <static-block-component page="event" position="top"></static-block-component>
      <div class="content-wrapper main-container">
        <div class="options-wrapper">
          <div class="calendar-wrapper">
            <date-picker
              v-model="date"
              mode="date"
              class="date-pickers"
              :input-props="{ placeholder: 'From Date' }"
              :masks="{ input: 'YYYY-MM-DD' }"
              @dayclick="onDayClick"
              readonly
            />
            <div class="clear-date-button" v-if="date" @click="clearDate">
              <CloseIcon />
            </div>
            <div class="icon-wrapper">
              <IconCalendar />
            </div>
          </div>
          <div v-if="teams.length" class="filtered-list-wrapper">
            <div class="search-icon">
              <IconSearch />
            </div>
            <FilteredList
              v-if="teams.length === teamsMeta"
              :filter-list="teams"
              @selected="onSelected"
              :getLabelCallback="getLabelForList"
            />
          </div>
        </div>
        <EventCategoryFilter v-bind:category-id.sync="selectedCategoryId" />
        <static-block-component page="event" position="middle"></static-block-component>
        <div class="text-state">
          <div>{{ returnState }}</div>
          <div class="ml-auto">Total: {{ meta.total }}</div>
        </div>
        <div v-if="eventList.length" class="card-wrapper">
          <c-w-event-card
            v-for="event in eventList"
            :key="event.id"
            :event="event"
            :selected-time-zone="timeZonesSelect"
          />
        </div>
        <h2 v-else-if="!processing" class="mt-5">
          {{ $t("There are no events yet") }}
        </h2>
        <loader v-show="processing" />
        <button-component
          :disabled="processing"
          v-if="canLoadMore && !processing"
          :text="$t('Show more')"
          @btn-click="showMoreHandle"
        />
      </div>
      <static-block-component page="event" position="bottom"></static-block-component>
    </div>
  </section>
</template>

<script>
import { EventBus } from "@/utils/event-bus";
import ls from "@/utils/locale-storage";
import { mapActions, mapState } from "vuex";
import { QUERY, STREAM_STATUS } from "@/const";
import ButtonComponent from "@/components/base-components/buttons/ButtonComponent";
import Loader from "@/components/Loader";
import StaticBlockComponent from "@/components/StaticBlockComponent";
import CWEventCard from "@/league/components/EventCards/CWEventCard";
import EventCategoryFilter from "@/league/components/Filters/EventCategoryFilter";
import FilteredList from "@/components/base-components/Favorites/FilteredList";
import SideBarUpcoming from "@/components/base-components/Favorites/SideBarUpcoming";
import CloseIcon from "@/components/icons/Close";

// Icons
import IconSearch from "@/components/icons/Search";
import IconCalendar from "@/components/icons/Calendar";

export default {
  name: "UpcomingScreenScreenView",
  components: {
    FilteredList,
    EventCategoryFilter,
    CWEventCard,
    IconSearch,
    IconCalendar,
    CloseIcon,
    SideBarUpcoming,
    Loader,
    ButtonComponent,
    StaticBlockComponent,
  },
  data() {
    return {
      requestParams: {
        limit: 12,
        offset: 0,
        stream_status: {
          live: STREAM_STATUS.live,
          coming: STREAM_STATUS.upcoming,
          vod: STREAM_STATUS.vod,
        },
      },
      label: {
        live: "Live",
        coming: "Upcoming",
        vod: "VOD",
      },
      meta: {
        total: null,
        limit: null,
        offset: null,
      },
      date: null,
      eventList: [],
      canLoadMore: false,
      processing: false,
      timeZonesSelect: "",
      selectedCategoryId: this.getCategoryIdFromRoute(),
      selectedTeamId: null,
      rememberList: [],
      initialized: false,
    };
  },
  watch: {
    "$route.path": "initialize",
    meta(newMeta) {
      const more = newMeta.total > newMeta.limit + newMeta.offset;
      more && (this.requestParams.offset += this.requestParams.limit);
      this.canLoadMore = more;
    },
    selectedCategoryId() {
      this.reset();
      this.getEventsList();
    },
  },
  computed: {
    ...mapState({
      teams: (state) => state.participant.participantsList,
      teamsMeta: (state) => state.participant.participantsMeta,
      dictionary: (state) => state.event.flatEventsCategoriesDictionary,
    }),
    returnState() {
      const streamStatus = this.returnAPIparams.stream_status.join("");
      if (streamStatus.toLowerCase() === "finished") {
        return "OnDemand";
      }
      return streamStatus;
    },
    isLogged() {
      return this.$store.getters.isLogged;
    },
    returnAPIparams() {
      let streamStatus = [];
      streamStatus.push(this.requestParams.stream_status[this.$route.name]);
      return {
        limit: 12,
        offset: this.requestParams.offset,
        stream_status: streamStatus,
      };
    },
  },
  mounted() {
    if (this.$store.getters.isLogged) {
      EventBus.$on("refreshFavorites", () => {
        this.initialize();
      });
      this.GET_USER_FAVORITES();
    }
    this.initialize();
    this.GET_EVENT_CATEGORIES();
  },
  beforeDestroy() {
    EventBus.$off("refreshFavorites");
  },
  methods: {
    ...mapActions([
      "GET_EVENTS_FAVORITES_LIST",
      "GET_EVENTS_LIST",
      "GET_EVENT_CATEGORIES",
      "GET_ALL_PARTICIPANTS",
      "GET_USER_FAVORITES",
    ]),
    initialize() {
      this.reset();
      this.eventList = [];
      this.meta = {
        total: null,
        limit: null,
        offset: null,
      };
      this.getEventsList();
      if (this.teams.length !== this.teamsMeta) {
        this.GET_ALL_PARTICIPANTS();
      }
    },
    getCategoryIdFromRoute() {      
      const query = this.$route.query;
      const id = query[QUERY.GROUP] || query[QUERY.DIVISION] || query[QUERY.CHANNEL];
      return (id && Number(id)) || null;
    },
    reset() {
      this.requestParams.offset = 0;
      this.eventList = [];
    },

    onZoneSelected(timeZone) {
      this.timeZonesSelect = timeZone;
    },

    getRequestParams() {
      const params = Object.assign({}, this.returnAPIparams);
      this.rememberList = ls.get("eventsRememberList") || [];
      if (this.date) {
        params.start_date = parseInt(new Date(this.date) / 1000);
      }
      if (this.selectedCategoryId) {
        params.categories = [this.selectedCategoryId];
        params.recursively_categories = 1;
      }
      if (this.rememberList?.length > 0 && this.isLogged) {
        const categoryIds = this.rememberList
          .filter((item) => item.type === "category")
          .map((item) => item.id);
        const teamIds = this.rememberList
          .filter((item) => item.type === "participant")
          .map((item) => item.id);
        if (params.categories?.length > 0) {
          params.categories = [...params.categories, ...categoryIds];
        } else {
          params.recursively_categories = 1;
          params.categories = categoryIds;
        }
        params.participants = [...teamIds];
      }
      if (this.selectedTeamId) {
        if (params.participants) {
          params.participants = [...params.participants, ...[this.selectedTeamId]];
        } else {
          params.participants = [...[this.selectedTeamId]];
        }
       
      }
      if (
          params.participants &&
          params.participants.length !== 0 &&
          params.categories &&
          params.categories.length !== 0
        ) {
          params.mode = "or";
        }
      return params;
    },
    getEventsList() {
      this.processing = true;
      if (this.getRequestParams().mode === "or") {
        this.GET_EVENTS_FAVORITES_LIST(this.getRequestParams())
          .then((res) => {
            res.data.forEach((data) => {
              let index = this.eventList.findIndex((event) => event.id === data.id);
              if (index === -1) {
                this.eventList.push(data);
              } else {
                this.eventList[index] = data;
              }
            });
            this.meta = res.meta;
          })
          .finally(() => {
            this.processing = false;
          });
      } else {
        this.GET_EVENTS_LIST(this.getRequestParams())
          .then((res) => {
            res.data.forEach((data) => {
              let index = this.eventList.findIndex((event) => event.id === data.id);
              if (index === -1) {
                this.eventList.push(data);
              } else {
                this.eventList[index] = data;
              }
            });
            this.meta = res.meta;
          })
          .finally(() => {
            this.processing = false;
          });
      }
    },

    showMoreHandle() {
      this.getEventsList();
    },
    onDayClick(e) {
      this.date = e.date;
      this.resetCall();
    },
    resetCall() {
      this.reset();
      this.getEventsList();
    },
    clearDate() {
      this.date = null;
      this.resetCall();
    },
    onSelected(data) {
      this.selectedTeamId = data?.id;
      this.resetCall();
    },
    getLabelForList(item) {
      const division = item.properties?.Division?.[0];
      const group = item.properties?.Group?.[0];
      const name = item?.name;
      if (!group && !division) return name;
      const concatSubName = [division, group].join(" | ");
      return `${name} - ${concatSubName}`;
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/css/colors";
@import "src/assets/css/mixins";

@include media-min(sm) {
  .grid-wrapper {
    display: grid;
    grid-template-columns: 1fr 2fr;
  }
}

@include media-max(sm) {
  .grid-wrapper {
    display: block;
  }
}

.ml-left {
  margin-left: auto;
}
.max-container {
  max-width: 1100px;
}

.filtered-list-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  border-radius: 2px;
  .search-icon {
    width: 3rem;
    height: 100%;
    z-index: 2;
    padding: 0.5rem;
    background: white;
    border-radius: 2px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
}
.content-wrapper {
  padding: 1rem 0;
}
.card-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  gap: 1.5rem;
  margin-bottom: 3rem;
  font-size: 0.8rem;
  @include media-max(xs) {
    grid-template-columns: 1fr;
  }
}

.options-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  gap: 1rem;
  align-items: center;
  padding-bottom: 1rem;

  .date-pickers {
    min-width: 300px;
    text-transform: none;
  }
}
</style>

<style lang="scss">
.text-state {
  display: flex;
  text-transform: capitalize;
  font-size: 22px;
  text-align: start;
  font-style: normal;
  text-decoration: none;
  color: var(--text-primary);
  padding-bottom: 1rem;
}
.date-pickers {
  display: flex;
  input {
    font-size: 1.1rem;
    padding-left: 4rem;
    line-height: 1.5;
    border-radius: 4px;
    border: 0px;
  }
}

.clear-date-button {
  position: absolute;
  top: 50%;
  right: 0.2rem;
  transform: translateY(-50%);
  cursor: pointer;
  font-weight: 1000;
  font-size: 1rem;
  font-style: normal;
  color: var(--settings-watch-button-background);
}

.calendar-wrapper {
  position: relative;
  .icon-wrapper {
    position: absolute;
    z-index: 2;
    width: 3rem;
    top: 1px;
    left: 1px;
    bottom: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    svg {
      width: 100%;
      height: 70%;
    }
  }
}
</style>
