<template>
  <div class="screen-wrapper">
    <div class="controls-wrapper main-container">
      <div
        v-if="!searchQuery"
        class="categories-wrapper"
        :class="{ opened: categoryListOpened }"
        @click.prevent.stop="toggleCategoryList"
      >
        <ButtonComponent
          v-if="mobileView"
          @btn-click="toggleCategoryList"
          variant="icon"
          class="category-toggler"
        >
          <folder-icon />
        </ButtonComponent>
        <div class="category-list" :class="{ opened: categoryListOpened }">
          <vod-categories
            @category-selected="handleSelectCategory"
            :mobile="mobileView"
            :screen_limit="screen_limit"
          />
        </div>
        <button-component
          v-show="categoryListOpened"
          variant="icon"
          class="close-category"
          @btn-click="toggleCategoryList"
        >
          <x-mark-icon />
        </button-component>
      </div>
      <div class="vod-search">
        <search-field @search="onSearch" />
      </div>
    </div>
    <div class="grid-section-wrapper main-container">
      <template v-if="searchQuery">
        <search-grid-component
          :search="searchQuery"
          :searchingData="searchingVod"
          :searchProcessing="searchProcessing"
        >
          <template v-slot:default="{ props }">
            <vod-grid-item :vod-info="props" />
          </template>
        </search-grid-component>
        <Loader v-if="searchProcessing" />
        <div class="more-btn">
          <ButtonComponent
            v-if="!searchProcessing && showSearchMore"
            :text="$t('Show more')"
            @btn-click="onSearchMore"
          />
        </div>
      </template>
      <template v-else>
        <div>
          <h2 class="category-title">
            {{ selectedCategoryTitle }}
          </h2>
          <p v-if="categoryDescription" class="category-description">
            {{ categoryDescription }}
          </p>
        </div>
        <vod-grid :category-id="categoryId" />
      </template>
    </div>
  </div>
</template>

<script>
import VodCategories from '../components/vod/VodCategories';
import VodGrid from '../components/vod/VodGrid';
import FolderIcon from '../../assets/img/folder-icon';
import ButtonComponent from '../../components/base-components/buttons/ButtonComponent';
import SearchField from '../../components/base-components/SearchField';
import { mapActions } from 'vuex';
import SearchGridComponent from '../components/main/SearchGridComponent';
import VodGridItem from '../components/vod/VodGridItem';
import Loader from '../../components/Loader';
import { ERROR } from '@/const';
import XMarkIcon from '../../assets/img/XMarkIcon';

const MOBILE_VIEW_BREAKPOINT = 768;
const BREAKPOINTS = {
  1920: 6,
  1400: 5,
  1200: 4,
  1080: 3,
};
const SEARCH = {
  OFFSET: 0,
  LIMIT: 12,
};
export default {
  name: 'VodLibraryScreen',
  components: {
    XMarkIcon,
    Loader,
    VodGridItem,
    SearchGridComponent,
    SearchField,
    ButtonComponent,
    FolderIcon,
    VodCategories,
    VodGrid,
  },
  data() {
    return {
      selectedCategory: null,
      categoryListOpened: false,
      mobileView: false,
      screen_limit: 6,
      abortController: null,
      searchProcessing: false,
      searchingVod: [],
      searchQuery: '',
      showSearchMore: false,
      searchOffset: SEARCH.OFFSET,
    };
  },
  methods: {
    ...mapActions(['SEARCH_IN_VOD']),
    handleSelectCategory(category) {
      this.selectedCategory = category;
    },
    toggleCategoryList() {
      this.categoryListOpened = !this.categoryListOpened;
    },
    checkMobile() {
      const width = document.body.clientWidth;
      const resolutions = Object.keys(BREAKPOINTS).filter(
        (rez) => rez <= width
      );
      if (resolutions.length) {
        const max = Math.max(...resolutions.map(Number));
        this.screen_limit = BREAKPOINTS[max];
      } else {
        this.screen_limit = 2;
      }
      this.mobileView = document.body.clientWidth <= MOBILE_VIEW_BREAKPOINT;
    },
    searchRequest() {
      this.searchProcessing = true;
      const controller = new AbortController();
      this.abortController = controller;
      const params = {
        limit: SEARCH.LIMIT,
        offset: this.searchOffset,
        title: this.searchQuery,
        abortSignal: controller.signal,
      };
      this.SEARCH_IN_VOD(params)
        .then(({ data, meta }) => {
          this.searchingVod = this.searchingVod.concat(data);
          this.showSearchMore = meta.total > meta.limit + meta.offset;
        })
        .catch((err) => {
          if (err.code === ERROR.CANCELED) {
            //...
          }
        })
        .finally(() => {
          this.abortController = null;
          this.searchProcessing = false;
        });
    },
    onSearch(title) {
      this.abortController?.abort?.();
      this.searchQuery = title;
      this.searchingVod = [];
      if (!title) {
        this.searchProcessing = false;
        return;
      }
      this.searchRequest();
    },
    onSearchMore() {
      this.searchOffset += SEARCH.LIMIT;
      this.searchRequest();
    },
  },
  computed: {
    categoryId() {
      return Number(this.selectedCategory?.id || null);
    },
    selectedCategoryTitle() {
      return (this.selectedCategory?.id && this.selectedCategory?.title) || '';
    },
    categoryDescription() {
      return this.selectedCategory?.description || null;
    },
  },
  mounted() {
    const self = this;
    this.checkMobile();
    window.addEventListener('resize', self.checkMobile);
  },
};
</script>

<style scoped lang="scss">
@import 'src/assets/css/colors';
@import 'src/assets/css/mixins';

.screen-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  gap: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;
  max-width: 100vw;
  color: $text-primary;
  @include media-max(sm) {
    margin-top: 0;
  }
}

.category-title {
  font-size: 2rem;
  letter-spacing: 0.003rem;
  line-height: 1;
  text-align: left;
  color: $accent-warning;
}
.category-description {
  text-align: left;
}

.category-list {
  @include media-max(sm) {
    background: $secondary-background;
    padding-top: 1rem;
    padding-bottom: 2rem;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 1;
    transition: all 0.2s linear;
    transform: translateX(-100%);
    max-width: 80vw;
    width: 100%;
    &.opened {
      transform: translateX(0);
    }
  }
}

.categories-wrapper {
  button {
    display: none;
  }
  .close-category {
    display: none;
  }
  @include media-max(sm) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 4rem;
    &.opened {
      backdrop-filter: blur(10px);
      z-index: 9999;
      width: 100vw;
      max-width: 100vw;
      height: 100vh;
      background: rgba($text-tertiary-dark-rgb, 0.5);
      .close-category {
        position: absolute;
        z-index: 10;
        top: 1rem;
        right: 1rem;
        width: 3rem;
        height: 3rem;
        display: block;
        padding: 0.5rem;
        svg {
          color: $secondary-background;
        }
      }
    }
    button {
      display: block;
      svg {
        color: $text-tertiary-dark;
      }
    }
  }
}
.grid-section-wrapper {
  --vod-title-color: var(--text-primary);
  --vod-subtitle-color: var(--text-primary);

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 1.5rem;
  @include media-max(sm) {
    margin-top: 0.5rem;
    width: auto;
  }
}

.category-toggler {
  outline: none;
  border: none;
  background: transparent;
  width: 3rem;
  height: 3rem;
  padding: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 5rem;
  color: $main-background;
  @include media-max(xxs) {
    margin-left: 0.5rem;
  }
}
.vod-search {
  max-width: 40rem;
  width: calc(80vw - 1rem);
  align-self: flex-end;
  margin-left: auto;
}

.controls-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5rem;
  @include media-max(sm) {
    flex-direction: column;
    margin-top: 1rem;
  }
}

.more-btn {
  margin-bottom: 2rem;
}
</style>
