import { render, staticRenderFns } from "./PackagesScreen.vue?vue&type=template&id=a3aed538&scoped=true&"
import script from "./PackagesScreen.vue?vue&type=script&lang=js&"
export * from "./PackagesScreen.vue?vue&type=script&lang=js&"
import style0 from "./PackagesScreen.vue?vue&type=style&index=0&id=a3aed538&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3aed538",
  null
  
)

export default component.exports