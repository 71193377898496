<template>
    <div class="container mt-5">
        <div class="columns is-vcentered is-multiline is-mobile">
            <div class="column is-2-desktop">
                <date-picker mode="range" :columns="$screens({ default: 1, lg: 2 })" v-model="dates"
                             @input="updateEvents"></date-picker>
            </div>
            <div class="column is-2-desktop" v-if="allParticipants">
                <div class="select is-rounded">
                    <select @input="inputParticipants">
                        <option value="">Team</option>
                        <option v-for="item in allParticipants"
                                :key="item.id"
                                :value="item.id">{{item.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div v-for="(key, property) in filters"
                 :key="property"
                 class="column is-narrow">
                <div class="select is-rounded">
                    <select :name="property" @input="input">
                        <option>{{property}}</option>
                        <option v-for="(value) of key"
                                :key="value"
                                :value="value">{{value}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <past-games-component :events="eventsToShow">

        </past-games-component>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    import {mapCacheActions} from "vuex-cache";
    import PastGamesComponent from "../components/main/PastGamesComponent";

    export default {
        name: "ScheduleScreen",
        components: {PastGamesComponent},
        data() {
            return {
                requestEventsParams: {
                    participants: [],
                },
                filters: {},
                selectedFilters: {},
                dates: {
                    start: new Date(),
                    end: new Date(),
                },
                events: [],
                eventsToShow: [],
            }
        },
        methods: {
            ...mapCacheActions(['GET_EVENTS_LIST', 'GET_PARTICIPANTS_LIST']),
            input(el) {
                if (el.target.value) {
                    this.selectedFilters[el.target.name] = el.target.value;
                } else {
                    delete this.selectedFilters[el.target.name];
                }
                this.updateEventsByPropertiesValue();

            },
            updateEventsByPropertiesValue() {
                if (Object.keys(this.selectedFilters).length) {
                    this.eventsToShow = [];
                    Object.entries(this.selectedFilters).forEach(([key, value]) => {
                        this.events.forEach(item => {
                            if (item.participants) {
                                item.participants.forEach(participant => {
                                    if (participant.properties && participant.properties[key]) {
                                        participant.properties[key].forEach((propValue) => {
                                            if (propValue == value) {
                                                this.eventsToShow.push(item);
                                            }
                                        })
                                    }
                                });
                            }
                        });
                    });
                    this.eventsToShow =  Array.from(new Set(this.eventsToShow));
                }
                this.setFilters();
            },
            inputParticipants(el) {
                this.requestEventsParams.participants[0] = el.target.value;
                this.updateEvents();
            },
            updateEvents() {
                this.GET_EVENTS_LIST(this.prepareParameters())
                    .then((response) => {
                        this.events = response.data;
                        this.eventsToShow = response.data;
                        this.updateEventsByPropertiesValue();
                    });
            },
            getParticipant() {
                this.GET_PARTICIPANTS_LIST();
            },
            getFilterValues(index) {
                return this.filters[index].values().next().value;
            },
            prepareParameters() {
                let params = JSON.parse(JSON.stringify(this.requestEventsParams)); //copy params
                Object.keys(params).forEach(key => {
                    if (!params[key] || params[key] == 0) {
                        delete params[key]
                    }
                });
                params.start_date = Math.round(this.dates.start.getTime() / 1000);
                params.end_date = Math.round(this.dates.end.getTime() / 1000);
                return params;
            },
            setFilters() {
                this.filters = {};
                this.eventsToShow.forEach(event => {
                    if (event.participants) {
                        event.participants.forEach(participant => {
                            if (participant.properties) {
                                Object.keys(participant.properties);
                                Object.entries(participant.properties).forEach(([key, values]) => {
                                    if (!this.filters[key]) {
                                        let set = [];
                                        values.forEach(value =>{set.push(value)});

                                        this.filters[key] = set;
                                    } else {
                                        let set = this.filters[key];
                                        values.forEach(value =>{set.push(value)});
                                        this.filters[key] = Array.from(new Set(set));
                                    }
                                });
                            }
                        })
                    }
                });

            },
        },
        computed: {
            ...mapState({
                allParticipants: state => state.participant.participantsList,
            }),
        },
        created() {
            this.dates.start.setMonth(this.dates.start.getMonth() - 1);
            this.dates.start.setHours(0, 0, 0, 0);
            this.dates.end.setMonth(this.dates.end.getMonth() + 1);
            this.dates.end.setHours(23, 59, 59, 0);
            this.getParticipant();
            this.updateEvents();

            this.eventsToShow = this.events;
            //this.$store.getters['getParticipantsByProperties']('Sports', 'Football')
        },
    }
</script>

<style lang="scss" scoped>

    select > option {
        border-radius: 15px;
    }
</style>