<template>
  <section class="grid-wrapper">
    <SideBarUpcomingSelector v-if="dictionary.length > 0" />
    <div class="max-container">
      <static-block-component
        page="event"
        position="top"
      ></static-block-component>
      <div class="content-wrapper main-container">
        <div class="options-wrapper">
          <div class="calendar-wrapper">
            <date-picker
              :key="displayingRouteName + stateRefresher"
              v-model="date"
              mode="date"
              class="date-pickers"
              :input-props="{ placeholder: 'From Date' }"
              :masks="{ input: 'YYYY-MM-DD' }"
              @dayclick="onDayClick"
            />
            <div class="clear-date-button" v-if="date" @click="clearDate">
              <CloseIcon />
            </div>
            <div class="icon-wrapper">
              <IconCalendar />
            </div>
          </div>
          <div v-if="teams.length" class="filtered-list-wrapper">
            <div class="search-icon">
              <IconSearch />
            </div>
            <FilteredList
              ref="searchList"
              :filter-list="teams"
              @selected="stateResetterFromSearch"
              :getLabelCallback="getLabelForList"
            />
          </div>
        </div>
        <EventCategoryFilter
          ref="filterList"
          v-bind:category-id.sync="selectedCategoryId"
        />
        <static-block-component
          page="event"
          position="middle"
        ></static-block-component>
        <section v-if="getSelectedFavorite && getSelectedFavorite !== -1">
          <div class="text-state">
            <div class="my-auto mr-2">
              <ImageComponent
                class="imgSize"
                :path="getSelectedFavorite.image"
              />
            </div>
            <div class="my-auto">
              {{
                getSelectedFavorite.name
                  ? getSelectedFavorite.name
                  : 'All Favorites'
              }}
              - {{ returnState }}
            </div>
          </div>
        </section>
        <section v-else class="text-state">
          <div>
            {{ displayingRouteName }}
            {{
              !isLogged && $route.name === 'all'
                ? $t(' - Displaying all events, favorite to filter')
                : ''
            }}
          </div>
        </section>
        <div v-if="eventList.length" class="card-wrapper">
          <c-w-event-card
            v-for="event in eventList"
            :key="event.id"
            :event="event"
            :selected-time-zone="timeZonesSelect"
          />
        </div>
        <h2 v-else-if="!processing" class="mt-5">
          {{ $t('There are no events yet') }}
        </h2>
        <loader v-show="processing" />
        <button-component
          :disabled="processing"
          v-if="canLoadMore && !processing"
          :text="$t('Show more')"
          @btn-click="showMoreHandle"
        />
      </div>
      <static-block-component
        page="event"
        position="bottom"
      ></static-block-component>
    </div>
  </section>
</template>

<script>
import { EventBus } from '@/utils/event-bus';
import { mapActions, mapState, mapGetters } from 'vuex';
import { QUERY, STREAM_STATUS } from '@/const';
import ButtonComponent from '@/components/base-components/buttons/ButtonComponent';
import Loader from '@/components/Loader';
import StaticBlockComponent from '@/components/StaticBlockComponent';
import CWEventCard from '@/league/components/EventCards/CWEventCard';
import EventCategoryFilter from '@/league/components/Filters/EventCategoryFilter';
import FilteredList from '@/components/base-components/Favorites/FilteredList';
import SideBarUpcomingSelector from '@/components/base-components/Favorites/SideBarUpcomingSelector';
import ImageComponent from '@/components/base-components/ImageComponent';
import CloseIcon from '@/components/icons/Close';

// Icons
import IconSearch from '@/components/icons/Search';
import IconCalendar from '@/components/icons/Calendar';

export default {
  name: 'UpcomingScreenScreenView',
  components: {
    FilteredList,
    EventCategoryFilter,
    CWEventCard,
    IconSearch,
    IconCalendar,
    SideBarUpcomingSelector,
    Loader,
    ButtonComponent,
    StaticBlockComponent,
    ImageComponent,
    CloseIcon,
  },
  data() {
    return {
      requestParams: {
        limit: 12,
        offset: 0,
        stream_status: {
          all: STREAM_STATUS.all,
          live: STREAM_STATUS.live,
          upcoming: STREAM_STATUS.upcoming,
          vod: STREAM_STATUS.vod,
        },
      },
      meta: {
        total: null,
        limit: null,
        offset: null,
      },
      favorites: null,
      date: null,
      eventList: [],
      canLoadMore: false,
      processing: false,
      allSelected: false,
      timeZonesSelect: '',
      selectedCategoryId: this.getCategoryIdFromRoute(),
      selectedTeamId: null,
      stateRefresher: 0,
      rememberList: [],
      initialized: false,
    };
  },
  watch: {
    returnRoutePath(newValue) {
      if (newValue) {
        this.date = null;
        this.stateRefresher = this.stateRefresher + 1;
       // this.removeSelectedFavorites();
        this.initialize();
      }
    },
    meta(newMeta) {
      const more = newMeta.total > newMeta.limit + newMeta.offset;
      more && (this.requestParams.offset += this.requestParams.limit);
      this.canLoadMore = more;
    },
    selectedCategoryId() {
      if (this.selectedCategoryId) {
        // if (!!this.$refs.searchList && this.$refs.searchList.search) {
        //   this.date = null;
        //   this.stateRefresher = this.stateRefresher + 1;
        // }
        EventBus.$emit('resetSearchFavorite');
        this.$store.commit('UPDATE_SELECT_FAVORITE', '');
        this.reset();
        this.getEventsList();
      }
    },
    // selectedTeamId() {
    //   this.reset();
    //   this.getEventsList();
    // },
    // getFavorites(newValue, oldValue) {
    //   if (newValue.length !== 0 && this.$route.name === 'all') {
    //     this.removeSelectedFavorites();
    //     this.initialize();
    //   }
    // },
  },
  computed: {
    ...mapState({
      teams: (state) => state.participant.participantsList,
      teamsMeta: (state) => state.participant.participantsMeta,
      dictionary: (state) => state.event.flatEventsCategoriesDictionary,
    }),
    ...mapGetters({
      getFavorites: 'getFavorites',
      getSelectedFavorite: 'getSelectedFavorite',
    }),
    returnState() {
      const streamStatus = this.returnAPIparams.stream_status.join('');
      if (streamStatus.toLowerCase() === 'finished') {
        return 'OnDemand';
      } else if (streamStatus.toLowerCase() === 'liveupcomingfinished') {
        return 'All';
      }
      return streamStatus;
    },
    returnRoutePath() {
      return this.$route.path;
    },
    displayingRouteName() {
      return this.$route.name === 'finished'
        ? 'On Demand'
        : this.requestParams.stream_status[this.$route.name];
    },
    isLogged() {
      return this.$store.getters.isLogged;
    },
    returnAPIparams() {
      let streamStatus = [];
      if (this.$route.name === 'all') {
        streamStatus = [
          STREAM_STATUS.live,
          STREAM_STATUS.upcoming,
          STREAM_STATUS.vod,
        ];
      } else {
        streamStatus.push(this.requestParams.stream_status[this.$route.name]);
      }
      return {
        limit: 12,
        offset: this.requestParams.offset,
        stream_status: streamStatus,
      };
    },
  },
  mounted() {
      EventBus.$on('refreshFavoritesSpecific', () => {
        this.removeSelectedFavorites();
        this.$nextTick(() => {
          this.initialize();
        });
      });
      
    if (this.$store.getters.isLogged) {
      this.GET_USER_FAVORITES();
    }
    // EventBus.$on('resetPickedFavorite', () => {
    //   console.log("RESETED ?")
    //   this.removeSelectedFavorites();
    // });
    this.initialize();
    this.GET_EVENT_CATEGORIES();
  },
  beforeDestroy() {
    EventBus.$off('refreshFavoritesSpecific');
  },
  methods: {
    ...mapActions([
      'GET_EVENTS_FAVORITES_LIST',
      'GET_EVENTS_LIST',
      'GET_EVENT_CATEGORIES',
      'GET_ALL_PARTICIPANTS',
      'GET_USER_FAVORITES',
    ]),
    initialize() {
      this.reset();
      this.eventList = [];
      this.meta = {
        total: null,
        limit: null,
        offset: null,
      };
      this.getEventsList();
      if (this.teams.length !== this.teamsMeta) {
        this.GET_ALL_PARTICIPANTS();
      }
    },
    getCategoryIdFromRoute() {
      const query = this.$route.query;
      const id =
        query[QUERY.GROUP] || query[QUERY.DIVISION] || query[QUERY.CHANNEL];
      return (id && Number(id)) || null;
    },
    reset() {
      this.requestParams.offset = 0;
      this.eventList = [];
    },
    onZoneSelected(timeZone) {
      this.timeZonesSelect = timeZone;
    },
    getRequestParams() {
      const params = Object.assign({}, this.returnAPIparams);
      if (this.date) {
        params.start_date = parseInt(new Date(this.date) / 1000);
      }
      if (this.selectedCategoryId) {
        params.categories = [this.selectedCategoryId];
        params.recursively_categories = 1;
      }
      if (!!this.getSelectedFavorite) {
        if (this.getSelectedFavorite.favorite_type === 'category') {
          params.categories = [this.getSelectedFavorite.favorite_id];
          params.recursively_categories = 1;
        }
        if (this.getSelectedFavorite.favorite_type === 'participant') {
          params.participants = [this.getSelectedFavorite.favorite_id];
        }
      }
      if (this.getSelectedFavorite === 'all') {
        const categoryIds = this.getFavorites
          .filter((item) => item.favorite_type === 'category')
          .map((item) => item.favorite_id);
        const teamIds = this.getFavorites
          .filter((item) => item.favorite_type === 'participant')
          .map((item) => item.favorite_id);
        params.categories = [...categoryIds];
        params.participants = [...teamIds];
        params.recursively_categories = 1;
      }
      if (
        params.participants &&
        params.participants.length !== 0 &&
        params.categories &&
        params.categories.length !== 0
      ) {
        params.mode = 'or';
      }
      return params;
    },
    getEventsList() {
      // this.$nextTick(() => {
      this.processing = true;
      if (this.getRequestParams().mode === 'or') {
        this.GET_EVENTS_FAVORITES_LIST(this.getRequestParams())
          .then((res) => {
            res.data.forEach((data) => {
              let index = this.eventList.findIndex(
                (event) => event.id === data.id
              );
              if (index === -1) {
                this.eventList.push(data);
              } else {
                this.eventList[index] = data;
              }
            });
            this.meta = res.meta;
          })
          .finally(() => {
            this.processing = false;
          });
      } else {
        this.GET_EVENTS_LIST(this.getRequestParams())
          .then((res) => {
            res.data.forEach((data) => {
              let index = this.eventList.findIndex(
                (event) => event.id === data.id
              );
              if (index === -1) {
                this.eventList.push(data);
              } else {
                this.eventList[index] = data;
              }
            });
            this.meta = res.meta;
          })
          .finally(() => {
            this.processing = false;
          });
      }
      // });
    },
    showMoreHandle() {
      this.getEventsList();
    },
    onDayClick(e) {
      this.date = e.date;
      this.initialize();
    },
    // resetCall() {
    //   this.reset();
    //   // this.getEventsList();
    // },
    clearDate() {
      this.date = null;
      this.getEventsList();
    },
    removeSelectedFavorites() {
      if (this.teams.length === this.teamsMeta) {
        this.$refs.searchList.clearInputText();
      }
      this.$refs.filterList.clearInputText();
    },
    stateResetterFromSearch(item) {
      console.log("item?? 2", item)
      if (item) {
        // console.log('hello', this.selectedCategoryId);
        if (!!this.selectedCategoryId) {
          this.date = null;
          this.stateRefresher = this.stateRefresher + 1;
        }
        EventBus.$emit('resetPickedFavorite');
        this.$store.commit('UPDATE_SELECT_FAVORITE', {
          favorite_id: item.id,
          favorite_type: 'participant',
          name: item.name,
          image: item.images,
        });
        this.$nextTick(() => {
          this.initialize();
        });
      }
    },
    getLabelForList(item) {
      const division = item.properties?.Division?.[0];
      const group = item.properties?.Group?.[0];
      const name = item?.name;
      if (!group && !division) return name;
      const concatSubName = [division, group].join(' | ');
      return `${name} - ${concatSubName}`;
    },
  },
};
</script>

<style scoped lang="scss">
@import 'src/assets/css/colors';
@import 'src/assets/css/mixins';

@include media-min(sm) {
  .grid-wrapper {
    display: grid;
    grid-template-columns: 1fr 2fr;
  }
}

@include media-max(sm) {
  .grid-wrapper {
    display: block;
  }
}
.max-container {
  max-width: 1100px;
}

.filtered-list-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  border-radius: 2px;
  .search-icon {
    width: 3rem;
    height: 100%;
    z-index: 2;
    padding: 0.5rem;
    background: white;
    border-radius: 2px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
}
.content-wrapper {
  padding: 1rem 0;
}
.card-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  gap: 1.5rem;
  margin-bottom: 3rem;
  font-size: 0.8rem;
  @include media-max(xs) {
    grid-template-columns: 1fr;
  }
}

.options-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  gap: 1rem;
  align-items: center;
  padding-bottom: 1rem;

  .date-pickers {
    min-width: 300px;
    text-transform: none;
  }
}
</style>

<style lang="scss">
.text-state {
  display: flex;
  text-transform: capitalize;
  font-size: 22px;
  text-align: start;
  font-style: normal;
  text-decoration: none;
  color: var(--text-primary);
  padding-bottom: 1rem;
}

.date-pickers {
  display: flex;
  input {
    font-size: 1.1rem;
    padding-left: 4rem;
    line-height: 1.5;
    border-radius: 4px;
    border: 0px;
  }
}
.calendar-wrapper {
  position: relative;
  .icon-wrapper {
    position: absolute;
    z-index: 2;
    width: 3rem;
    top: 1px;
    left: 1px;
    bottom: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    svg {
      width: 100%;
      height: 70%;
    }
  }
}
.imgSize {
  max-width: 30px;
  max-height: 30px;
}

.clear-date-button {
  position: absolute;
  top: 50%;
  right: 0.2rem;
  transform: translateY(-50%);
  cursor: pointer;
  font-weight: 1000;
  font-size: 1rem;
  font-style: normal;
  color: var(--settings-watch-button-background);
}
</style>
