<template>
	<div class="search-wrapper">
		<input
			type="text"
			class="search-input"
			v-model="query"
			:placeholder="$t('Search')"
		>
		<button-component variant="icon" class="search-button" :disabled="!query">
			<search-icon/>
		</button-component>
	</div>
</template>

<script>
  import ButtonComponent from "./buttons/ButtonComponent";
  import SearchIcon from "../../assets/img/searchIcon";
  export default {
    name: "SearchField",
    components: {SearchIcon, ButtonComponent},
    data(){
      return {
        query: ''
      }
    },
    watch: {
      query(newQuery){
        this.$emit('search', newQuery)
      }
    }
  }
</script>

<style scoped lang="scss">
	@import "src/assets/css/colors";

	.search-wrapper{
		display: flex;
		gap: 1rem;
	}
	.search-button{
		width: 2rem;
		height: 2rem;
		padding: .25rem;
		box-sizing: border-box;
		color: $text-primary;
		&:disabled{
			color: $text-tertiary-middle;
			background: transparent;
		}
	}
	.search-input{
		outline: none;
		border: none;
		border-bottom: 1px solid $text-tertiary-middle;
		background: transparent;
		width: 100%;
		&:focus{
			border-bottom: 1px solid $text-primary;
		}
	}

</style>