<template>
	<div>
		<h2 class="title">
			{{title}}
		</h2>
		<div class="grid-wrapper">
			<slot
				v-for="item in searchingData"
				:props="item"
			/>
		</div>
	</div>
</template>

<script>
  export default {
    name: "SearchGridComponent",
    props: {
      search: {
        type: String,
      },
      searchingData: {
        type: Array
      },
      searchProcessing:{
        type: Boolean
      }
    },
    computed: {
      title() {
        return this.searchProcessing || this.searchingData?.length ?
            this.$t("Search results for", {query: this.search}) :
            this.$t('No results found for', {query: this.search})
      }
    }
  }
</script>

<style scoped lang="scss">
	@import "src/assets/css/mixins";
	.title{
		margin-bottom: 2rem;
	}
	.grid-wrapper{
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(19rem, 1fr));
		gap: 1.5rem;
		@include media-max(xs){
			grid-template-columns: 1fr;
		}
	}
</style>