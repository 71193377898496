<template>
<div>
  <div style="max-width: 1140px; margin: 0 auto; margin-bottom: 0px; padding: 18px">
    <div class="alert alert-secondary alert-dismissible fade show" role="alert">
      <div class="banner-text">
        <p>
          Welcome to USA Hockey TV, a new and exciting destination for exclusive USA Hockey content.
          This platform will include a mixture of free and subscription-based content and will include live
          and on-demand games as well as other featured programming. Check the blue button at the
          bottom of each game card for information on what is required for a specific game.
        </p>
        <p>
          If you need assistance, please click the Support button above and someone will be happy to help
          you. Please note that all customer support is handled through email.
        </p>
        <p>
          Please note: All sales are final. There are no refunds or allowances.
        </p>
      </div>
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="container-wrapper main-container my-5" style="margin-top: 0px !important;">
    <div v-for="sub in subscriptions" :key="sub.id" class="package">
      <subscription-component :item="sub"/>
    </div>
    <div v-for="pak in packages" :key="pak.id" class="package">
      <package-component :item="pak"/>
    </div>
  </div>
</div>
</template>

<script>
import {mapState} from "vuex";
import PackageComponent from "@/league/components/main/PackageComponent";
import SubscriptionComponent from "@/league/components/main/SubscriptionComponent";
import GoogleAnalytics from "../../components/base-components/Analytics/GoogleAnalytics";
import {ORDER_TYPE} from "../../const";
import {mapCacheActions} from "vuex-cache";

export default {
  name: "PackagesScreen",
  components: {SubscriptionComponent, PackageComponent},
  PACKAGES_LIMIT: 40,
  SUBSCRIPTIONS_LIMIT: 20,
  data(){
    return {
      packagesReceived: false,
      subscriptionsReceived: false,
    }
  },
  methods: {
    ...mapCacheActions(['GET_PACKAGES', 'GET_SUBSCRIPTIONS']),
    sendAnalytics(){
      this.packagesReceived && this.subscriptionsReceived &&
      GoogleAnalytics.viewItemList({
        typedList: {
          [ORDER_TYPE.subscription] : this.subscriptions,
          [ORDER_TYPE.package] : this.packages,
        }
      })
    }
  },
  computed: {
    ...mapState({
      packages: state => state.payment.packagesList,
      subscriptions: state => state.payment.subscriptionsList,
    }),
  },
  watch:{
    packagesReceived(){
      this.sendAnalytics()
    },
    subscriptionsReceived(){
      this.sendAnalytics()
    },
  },
  mounted() {
    this.GET_SUBSCRIPTIONS({limit: this.$options.SUBSCRIPTIONS_LIMIT}).then(()=>{
      this.packagesReceived = true;
    });
    this.GET_PACKAGES({limit: this.$options.PACKAGES_LIMIT}).then(() => {
      this.subscriptionsReceived = true
    })
  }
}
</script>

<style scoped>
.banner-text{
  font-family: Roboto;
  font-size: 14px;
  text-transform: none;
  text-align: left;
  font-weight: normal;
}
.banner-text p{
  margin-bottom: .5rem;
}
.container-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
  gap: 1rem;
}
.package{
  width: 100%;
  display: flex;
}
</style>