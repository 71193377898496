<template>
    <div>
        <div class="columns is-centered mb-5 is-multiline">
            <div v-for="(item, index) in events" :key="index" class="column is-one-third">
<!--                <game-component v-if="item.participants[0]" :event="item">-->
                <game-component  :event="item">
                </game-component>
            </div>
        </div>
    </div>
</template>

<script>
    import GameComponent from "../../../components/main/GameComponent";

    export default {
        name: "PastGamesComponent",
        components: {GameComponent},
        props: {
            events: Array
        },
    }
</script>

<style scoped>

</style>